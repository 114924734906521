import { createRouter, createWebHistory } from 'vue-router';

import Session from '@/views/Session.vue';
import Form from '@/views/Form.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/session/:id', component: Session },
    { path: '/', component: Form },
    { path: '/:notFound(.*)', redirect: '/' },
  ],
});

export default router;

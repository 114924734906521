<template>
  <div class="w-full lg:w-3/4 mx-auto p-4">
    <header class="flex justify-between items-center mb-8">
      <h1 class="text-2xl font-bold text-gray-800">Received information</h1>
      <button
        @click="fetchData"
        class="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Refresh
      </button>
    </header>
    <div v-if="loading" class="text-center py-12">
      <custom-loader color="#4B5563" size="70px" />
    </div>
    <div v-else-if="error" class="text-center text-red-500 py-12">
      Error occured
    </div>
    <div v-else-if="session" class="mt-10">
      <div v-for="data of session.data" class="flex gap-5 mb-5">
        <p v-if="!data.id.startsWith('customData')" class="font-bold w-48">
          {{ data.id }} ({{ data.type }})
        </p>
        <p v-else class="font-bold w-48">
          {{ data.title.en }} ({{ data.type }})
        </p>
        <div v-if="data.value">
          <p v-if="data.type == 'text'">{{ data.value }}</p>
          <p
            v-if="data.type == 'file'"
            class="border text-gray-600 px-2 rounded-md"
          >
            <a :href="data.value" target="_blank">↓</a>
          </p>
        </div>
        <p v-else="data.value" class="italic">Not provided</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { inject } from 'vue';

export default {
  setup() {
    const api = inject('api');
    const route = useRoute();

    const id = route.params.id;

    const session = ref(null);
    const error = ref(null);
    const loading = ref(false);

    const fetchData = async () => {
      loading.value = true;

      try {
        const response = await api.getSession(id);
        session.value = response.data;
        error.value = null;
        loading.value = false;
      } catch (err) {
        console.error('Error fetching data:', err);
        error.value = err;
        loading.value = false;
      }
    };
    fetchData();

    const formatFileSize = (bytes) => {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
    };

    return {
      session,
      error,
      loading,
      fetchData,
      formatFileSize,
    };
  },
};
</script>

<template>
  <div class="w-full lg:w-3/4 mx-auto p-4">
    <p class="text-3xl font-bold mb-10 text-gray-800">Onboarding form</p>
    <p class="block text-gray-700 text-lg font-bold mb-5">Select a field</p>
    <div class="flex gap-4 mb-4">
      <select
        @change="onFieldSelectionChange"
        placeholder="Select a field"
        class="w-52 h-10 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        v-model="selectedOptionField"
      >
        <option value="" disabled selected>Select a field</option>
        <option
          v-for="field in availableFields.filter(
            (field) => !submittedFields.map((el) => el.id).includes(field.id)
          )"
          :key="field.id"
          :value="JSON.stringify(field)"
        >
          {{ `${field.id} (${field.type})` }}
        </option>
      </select>
      <input
        v-if="selectedField && selectedField.id == 'customData'"
        v-model="customDataTitleEn"
        placeholder="Title in English"
        class="w-52 h-10 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
      <input
        v-if="selectedField && selectedField.id == 'customData'"
        v-model="customDataTitleFr"
        placeholder="Title in French"
        class="w-52 h-10 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
    </div>
    <button
      @click="addField"
      type="button"
      :disabled="!selectedField"
      :class="
        !selectedField
          ? 'bg-gray-300 cursor-not-allowed'
          : 'bg-secondary cursor-pointer'
      "
      class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-14"
    >
      Add field
    </button>
    <div v-if="submittedFields.length > 0">
      <p class="text-lg font-bold mb-5 text-gray-800">
        Selected fields preview
      </p>
      <div
        v-for="field in submittedFields"
        :key="field.id"
        class="flex mb-4 items-center"
      >
        <div class="flex w-[400px] gap-3">
          <p class="font-bold">{{ field.id }}</p>
          <p v-if="field.title">
            {{ field.title.en + '/' + field.title.fr }}
          </p>
          <p v-if="field.title" class="">({{ field.type }})</p>
        </div>
        <button
          @click="removeField(field.id)"
          class="text-red-500 hover:text-red-700"
        >
          <svg
            class="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            ></path>
          </svg>
        </button>
      </div>
      <button
        @click="generateQRCode"
        type="button"
        :disabled="!submittedFields.length"
        :class="
          !submittedFields.length
            ? 'bg-gray-300 cursor-not-allowed'
            : 'bg-secondary cursor-pointer'
        "
        class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4 mb-14"
      >
        Generate QR code
      </button>

      <div
        v-if="displayQrCode"
        class="z-10 fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center"
      >
        <div
          class="bg-white md:w-1/2 md:h-1/2 mx-3 flex flex-col justify-center items-center rounded-lg shadow-md px-8 py-6 relative"
        >
          <qrcode-modal :fields="[...submittedFields]" />
          <button
            @click="displayQrCode = false"
            class="absolute top-2 right-2 text-gray-700"
          >
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, inject } from 'vue';
import { createToast } from 'mosha-vue-toastify';

export default {
  setup() {
    const api = inject('api');

    const availableFields = ref([]);
    const submittedFields = ref([]);
    // const submittedFields = ref([{ id: 'familyName' }]);
    const selectedField = ref(null);
    const selectedOptionField = ref('');
    const customDataTitleEn = ref(null);
    const customDataTitleFr = ref(null);
    const customDataIndex = ref(1);
    const displayQrCode = ref(false);

    api.getAllowedFields().then((res) => {
      availableFields.value = res.data;
    });

    function onFieldSelectionChange(event) {
      selectedField.value = JSON.parse(event.target.value);
    }

    function addField() {
      // add check
      // if (false) {
      //   createToast('Please fill in all the fields to add custom data', {
      //     position: 'bottom-center',
      //     hideProgressBar: true,
      //     timeout: 3000,
      //     transition: 'slide',
      //     toastBackgroundColor: '#ff4545',
      //   });
      // }

      submittedFields.value.push({
        id:
          selectedField.value.id == 'customData'
            ? selectedField.value.id + customDataIndex.value++
            : selectedField.value.id,
        ...(selectedField.value.id == 'customData' && {
          type: selectedField.value.type,
          title: {
            en: customDataTitleEn.value,
            fr: customDataTitleFr.value,
          },
        }),
      });

      selectedField.value = null;
      selectedOptionField.value = '';
      customDataTitleEn.value = null;
      customDataTitleFr.value = null;
    }

    const generateQRCode = () => {
      displayQrCode.value = true;
    };

    const removeField = (fieldId) => {
      submittedFields.value = submittedFields.value.filter(
        (field) => field.id !== fieldId
      );

      if (fieldId.startsWith('customData')) {
        customDataIndex.value--;
      }
    };

    return {
      submittedFields,
      availableFields,
      selectedField,
      selectedOptionField,
      customDataTitleFr,
      customDataTitleEn,
      addField,
      generateQRCode,
      displayQrCode,
      removeField,
      onFieldSelectionChange,
    };
  },
};
</script>
